:root {
    --dark: #000;
    --gray: #181818;
    --dark-transparency: rgba(10, 18, 31, 0.75);
    --primary: #36a8ff;
}

body {
    color: white;
    background: #000;
}

/* Ionic 

.segment-button-checked {
    color: black;
}

ion-segment-button.segment-button-checked {
    background-color: var(--primary);
    color: black !important;
}

ion-segment-button:not(.segment-button-checked) {
    background-color: black;
    color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
}

.segment-button-checked {
    color: var(--primary) !important;
}

/* Forms */

.form-control, .form-control:focus, .form-control:active, .form-control:focus-within {
    color: white;
    background-color: var(--gray);
}

/* Tables */

th {
    background-color: var(--gray);
}

td {
    color: white;
}

/* Menu */

.side-menu {
    background-color: #191919;
}

.main-panel {
    background: #fff;
}

/* Card */

.card {
    color: white;
    background-color: var(--gray);
    border: 0.5px solid white;
}

.dropdown-item {
    font-weight: bold;
    color: white;
}

.dropdown-item:hover {
    background-color: var(--primary);
}

/* Text */

.text-primary {
    color: var(--primary) !important;
}

label {
    color: white;
}

/* Overlays */

.overlay {
    background-color: #000;
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0.75;
    z-index: 1;
}


.black-overlay {
    background-color: #000;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    opacity: 0.4;
    z-index: 0;
}

/* Background */

.bg-gray {
    background-color: var(--gray);
}

.bg-primary {
    background-color: var(--primary) !important;
    border-color: var(--primary);
}

.bg-dark {
    background-color: var(--dark) !important;
}

.bg-opacity {
    background-color: var(--dark-transparency) !important;
}

.bg-black {
    background-color: #000;
}

/* Buttons */

.btn-primary, .btn-primary:focus,
.btn-primary:active {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--dark);
}

.btn-primary:hover {
    background-color: #000;
    color: var(--primary);
}

.btn-outline-primary,
.btn-outline-primary:focus,
.btn-outline-primary:active {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary);
}

.btn-outline-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--dark);
}

/* Elements */

#landing {
    color: white;
}

.main-panel {
    background-color: var(--dark);
    color: white;
}