@font-face {
    font-family: "Enixe";
    src: url("https://mylegion.mx/fonts/Enixe.woff") format("woff")
}

@font-face {
    font-family: "Valorant";
    src: url("https://mylegion.mx/fonts/Valorant.woff") format("woff")
}

h1,
h2 {
    font-family: "Enixe", sans-serif;
    font-weight: 700;
}

h3, h4, h5, h6, button, label, input, div, span, p, a {
    font-family: "Valorant", sans-serif;
}